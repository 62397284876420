import { React, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import './navbar.css'






const Navbar = () => { 
	const [showNavbar, setShowNavbar] = useState(true)
	
	const handleShowNavbar = () => {
		setShowNavbar(!showNavbar)
	}
	return (  
		<>
		<nav className="navbar">
			<div className="container">
				<div className="logo">
					<NavLink to="/">
						<Typography variant='button'>
						<	img src={require('../../images/logo.png')} width={80}/>
						</Typography>
					</NavLink>
				</div>
				<div className="menu-icon" onClick={handleShowNavbar}>
					<Typography variant='button'>
						<img src={require('../../images/hamburger.png')} width={80}/>
					</Typography>
				</div>
				<div className={`nav-elements  ${showNavbar && 'active'}`}>
					<ul>
						<li>
							<NavLink to="/">Home</NavLink>
						</li>
						<li>
							<NavLink to="/contact">Contact Us</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</nav>
		</> 
	); 
}; 

export default Navbar;

