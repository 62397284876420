import { React, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/NavBar';
import ContactForm from './components/Contact/ContactForm';
import Home from './pages'; 
import About from './pages/about';
import ContactFormLoneRiderWakeForestClawmachine from './components/Contact/ContactForm-LoneRiderWakeForest';

export default function App() {

        useEffect(() => {
                if (document) {
                  const stylesheet = document.createElement("link");
                  stylesheet.rel = "stylesheet";
                  stylesheet.href = "https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css";
            
                  document.head.appendChild(stylesheet);
                }
              }, []);


        return (

<>
    
      
      <Router>
      <Navbar/>
    <Routes>        
        <Route path='/' exact element={ <Home /> } /> 
        <Route path='/contact' element={ <ContactForm /> } /> 
        <Route path='/contact-LoneRider-WakeForest-ClawMachine' element={ <ContactFormLoneRiderWakeForestClawmachine /> } />  
    </Routes>
    </Router>
    
    </>

                
        );
}


