import React from 'react'; 

import Divider from '@mui/material/Divider';

const Home = () => { 
	return (
		<div>
		  <div className='homeLogo'>
            <img src={require('../images/logo.png')} alt='dbzvending logo' width={300}/>
		  </div>
		  <section className="content">
                
			<p>	
                Welcome to DBZ Vending, your trusted partner for all your vending needs in the Raleigh, North Carolina area. 
                We are a family-owned and operated business, with Brian and Derek at the helm, dedicated to providing top-notch 
                vending solutions with a personal touch.
			</p>
			
		  </section>

          <Divider variant="middle" />

          <section className="content">
          <header>
			<h1>
				<b>Our Story</b>
			</h1>
		  </header>
			<p>	
                Our journey began with a shared passion for exceptional customer service and a desire to bring convenience 
                and quality refreshments to businesses and organizations throughout Raleigh. As residents of this vibrant 
                community, we saw the need for a vending company that could deliver more than just snacks and drinks – we 
                wanted to offer an experience that exceeded expectations.
			</p>
			
            </section>

            <Divider variant="middle" />
          
          <section className="content">
          <header>
			<h1>
				<b>Meet the team</b>
			</h1>
		  </header>

            <div class="row">
                <div class="column-two-left">
                    <p>	
                        <b>Brian: </b> 
                        With a background in business management and a strong dedication to customer satisfaction, Brian has 
                        always been the visionary force behind DBZ Vending. He is committed to enhancing the vending industry by 
                        incorporating innovative technologies and tailoring our services to meet the unique needs of each client.
                    </p>
                </div>
                <div class="column-two-right">
                    <p>
                        <b>Derek: </b> 
                        Derek's technical expertise and attention to detail make him the backbone of DBZ Vending. He ensures 
                        that all our machines are well-maintained, stocked with a wide variety of products, and equipped with the 
                        latest payment options, providing a seamless vending experience for your employees and visitors.
                    </p>
                </div>
            </div>

			
            <br></br>
            
			
            </section>

            <Divider variant="middle" />

          <section className="content">
          <header>
			<h1>
				<b>Our Mission</b>
			</h1>
		  </header>
			<p>	
                At DBZ Vending, our mission is clear: to revolutionize the vending experience in Raleigh. We are dedicated 
                to offering a diverse selection of high-quality snacks, beverages, and fresh food options. Our commitment 
                to sustainability means we're always striving to reduce our environmental footprint through eco-friendly 
                practices.
			</p>
			
            </section>

            <Divider variant="middle" />

          <section className="content">
          <header>
			<h1>
				<b>What Sets Us Apart</b>
			</h1>
		  </header>
			<p>	
                <b>Personalized Service: </b>
                We understand that each client is unique, and we work closely with you to customize 
                the products and services that best fit your needs.
            </p>
            <br></br>
            <p>
                <b>Quality Assurance: </b>
                Every product in our machines is carefully selected to meet the highest standards of 
                taste and freshness, ensuring your satisfaction with every purchase.
            </p>
            <br></br>
            <p>
                <b>Technology Integration: </b>
                Our vending machines feature cutting-edge technology, from contactless payment 
                options to remote monitoring, ensuring the utmost convenience and reliability.
            </p>
            <br></br>
            <p>
                <b>Community Focus: </b>
                As local residents, we believe in giving back to our community. We actively support 
                local charities and initiatives, aiming to make Raleigh an even better place to live and work.
            </p>
            <br></br>
            <p>
                <b>Environmental Responsibility: </b>
                We take our environmental impact seriously and strive to make our vending 
                operations as sustainable as possible. This includes using energy-efficient machines and promoting recycling.
			</p>
			
            </section>

            <Divider variant="middle" />

          <section className="content">
          <header>
			<h1 href="/contact">
				<b>Get in Touch</b>
			</h1>
		  </header>
			<p>	
                We're excited to bring the best in vending services to Raleigh, and we'd love to discuss how DBZ Vending can 
                enhance your workplace or organization. Reach out to us today to learn more about our services, request a 
                customized vending solution, or simply chat with Brian and Derek. We look forward to becoming your trusted 
                vending partner.
			</p>
			
		  </section>
          
		  <footer>
			<p>&copy; 2023 DBZ Vending</p>
		  </footer>
		</div>
	  );
}; 

export default Home;
